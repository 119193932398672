<template>
  <div class="weeee">
    <van-icon name="down" size='32rpx' color='#4fc08d'/>
    <span>{{word}}:</span>
    <span>{{num}}</span>
    <span>{{danwei}}</span>
  </div>
</template>

<script>
export default {
  name: 'DivTitle',
  props: {
    word: String,
    num: Number,
    danwei: String
  }
}
</script>
<style>
.weeee{
  margin-bottom: 0.2rem;
  padding-left: 0.4rem;
}
.weeee span{
  color: #888;
  /* margin-left: 0.3rem; */
}
</style>
