<template>
  <div class="page ">
    <div v-if="loading">
      <div class="head"></div>
      <van-skeleton :row="6" />
    </div>
    <div v-else>
      <div class="head">
        <div class="title">
          <p>
            <span @click="pull_up" data-value="detail"  >{{xing_info.word}}</span>
            <span class="b"  @click="pull_up" data-value="detail"> 详情</span>
          </p>
        </div>
        <div class="about">
          <div class="l1">
            <p  class="black_tag" @click="pull_up" data-value="chengyu" > 谐音成语 </p>
            <p  class="black_tag" @click="pull_up" data-value="ciyu" > {{xing_info.word}}姓词语 </p>
            <p  class="black_tag" @click="pull_up" data-value="people" > 名人明星 </p>
          </div>
        </div>
      </div>
      <div id='jingxuan' class="mings_div mdd">
        <DivTitle  word="名字参考" :num= "num_info['name']" danwei="个" />
        <div class="names">
          <router-link :class="'one_name_jing background_'+ item.color"   v-for="(item, index) in list.name"  :key="index"  :to="{name : 'name_detail', query : {name : item.word}}">{{item.word}}</router-link>
          <span  class="more" data-value="xing_unvip|name"  @click="xml_go_to_goods_index">更 多</span>
        </div>
      </div>
      <div  v-if="num_info.zi>0"  class="mdd">
        <GreenTips word="谐音字"/>
        <ul class="zis">
          <span v-for="(zi, index) in list.zi" :key="index" class="xing_zi" >{{zi}}</span>
        </ul>
      </div>
      <div class="mdd">
        <DivTitle word="谐音诗词参考" :num= "num_info['shiju']" danwei="句" />
        <div v-for="(shiju, index) in list.shiju" :key="index" class="shiju" @click="get_poe_detail" :data-value="shiju.id">
          <div class="left" v-html="shiju.warn_p" ></div>
          <div class="right"><van-icon name="ellipsis" color="#ccc" size="0.5rem" /></div>
        </div>
        <p  class="line_more" data-value="xing_unvip|ciyu"  @click="xml_go_to_goods_index">- 更 多 -</p>
      </div>
      <WuXing :xing="xing_info.word" from_page='xing_unvip'></WuXing>
      <div class="mdd">
        <DivTitle word="谐音成语参考" :num= "num_info['chengyu']" danwei="个" />
        <ul>
          <li v-for="(item, index) in list.chengyu" :key="index" >
            <div class="word"  v-html="item.warn_p" ></div>
          </li>
          <li ><span class="more" data-value="xing_unvip|chengyu"  @click="xml_go_to_goods_index">更 多</span></li>
        </ul>
      </div>
      <div  class="mdd" >
        <DivTitle word="谐音词语参考" :num= "num_info['ciyu']" danwei="个" />
        <ul>
          <li v-for="(item, index) in list.ciyu" :key="index">
            <div class="name" v-html="item.warn_p"></div>
          </li>
          <li><span class="more" data-value="xing_unvip|ciyu"  @click="xml_go_to_goods_index">更 多</span></li>
        </ul>
      </div>
      <div  class="mdd">
        <DivTitle :word="xing_info['word'] + '姓名人'" :num= "num_info['people']" danwei="人" />
        <ul>
          <li v-for="(item, index) in list.people" :key="index" :data-value="item.name" @click="go_to_name_detail">
            <div class="word"><span :class="item.color"> {{item.name}}</span> </div>
          </li>
          <li> <span class="more" data-value="xing_unvip|people"  @click="xml_go_to_goods_index">更 多</span></li>
        </ul>
      </div>
      <div  class="mdd">
        <GreenTips  word="父母姓结合起名" />
        <button  class="black in_body" data-value="xing_unvip|fumu"  @click="xml_go_to_goods_index">添加妈妈姓氏</button>
      </div>
      <div  class="mdd">
        <GreenTips  word="起名配字工具" />
        <button  class="black in_body" data-value="xing_unvip|fit"  @click="xml_go_to_goods_index">添加固定字(如辈分)</button>
      </div>

      <button v-if="can_test=='bottom'"  class="unvip_to_xing"   @click="go_to_xing">体验大数据起名详细功能</button>
      <button v-else class="to_be_vip" data-value="xing_unvip|bottom" @click="xml_go_to_goods_index">了解更多大数据起名功能</button>
      <p class="warn_tips"> - 用数据和工具，拓展起名思路 -</p>
      <div class="foot" v-if="can_test=='fixed_bottom'">
        <button class="black w1" @click="go_to_xing" >体验大数据起名详细功能</button>
      </div>

      <ul id="last_right_b" class="last_right">
        <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
      </ul>

      <van-popup v-model:show="show.detail"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
        <DetailXing :info="xing_info" />
      </van-popup>
      <van-popup v-model:show="show.ciyu"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
        <ListCiyu :word="xing_info.word" tag="xing" from_page="name_xing" :can_see_more="can_see_more"/>
      </van-popup>
      <van-popup v-model:show="show.chengyu"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
        <ListChengyu :word="xing_info.word" tag="xing" from_page="name_xing" :can_see_more="can_see_more"/>
      </van-popup>
      <van-popup v-model:show="show.people"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
        <ListPeople :word="xing_info.word" tag="xing" from_page="name_xing" :can_see_more="can_see_more"/>
      </van-popup>
      <van-popup v-model:show="show.poe"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
        <van-skeleton v-if="loading2" :row="6" />
        <div v-else>
          <div class="head">
            <div class="title">
              <p class="title2">{{current_poe.title}}</p>
            </div>
            <div class="about">
              <div class="l1">
                <p>{{current_poe.dynasty}} -- {{current_poe.author}}</p>
              </div>
            </div>
          </div>

          <div  v-for="(juzi,index) in current_poe.body" :key="index" class="shiju2">
            {{juzi}}
          </div>
          <SearchName></SearchName>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>

import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import router from '@/router/index.js'
import ListCiyu from '@/components/other/ListCiyu'
import ListChengyu from '@/components/other/ListChengyu'
import ListPeople from '@/components/other/ListPeople'
import DetailXing from '@/components/other/DetailXing'
import SearchName from '@/components/other/SearchName'
import DivTitle from '@/components/items/DivTitle'
import GreenTips from '@/components/items/GreenTips'
import WuXing from '@/components/other/WuXing'

export default {
  name: 'name_xing_unvip',
  store,
  components: {
    ListCiyu, ListChengyu, ListPeople, DetailXing, SearchName, DivTitle, GreenTips, WuXing

  },
  data () {
    return {
      loading: true,
      loading2: false,
      base_img: store.state.base_img,
      show: { detail: false, poe: false, ciyu: false, chengyu: false, people: false },
      which_page: 0,
      can_test: '',
      can_see_more: false,
      current_poe: {},
      xing_info: { zi_info: { mean: {} } },
      list: { shiju: [], guide: [], ming: [] },
      page: { shiju: 1, guide: 1, ming: 1 },
      num_info: {
        shiju: 0,
        ming: 0,
        jing: 0
      }
    }
  },
  // shengyin: { duoyin: {'双多音': {}, '多音字': {} }, base: {韵律: {}, 同音: {}, 连读: {}, 连生母: {}, 连韵母: {} } },
  mounted () {
    this.xing_index()
  },

  methods: {
    xing_index () {
      axios.post('/xing_index_unvip/', { word: this.$route.query.word })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }

          this.xing_info = res.data.data.xing_info
          this.num_info = res.data.data.num_info
          this.list = res.data.data.list
          this.can_see_more = res.data.data.can_see_more
          this.can_test = res.data.data.can_test
          this.loading = false
          if (this.can_see_more) {
            router.push({ name: 'name_xing', query: { word: this.$route.query.word } })
            return
          }
          Utils.alert_tip_list('name_xing', res.data.data.tip_list)
        })
        .catch(err => {
          alert(err)
        })
    },
    go_to_xing () {
      router.push({ name: 'name_xing', query: { word: this.xing_info.word } })
    },
    get_poe_detail (e) {
      this.loading2 = true
      this.show.poe = true
      this.current_poe = {}
      axios.post('/poe_detail/', { shiju_id: e.currentTarget.dataset.value })
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.current_poe = res.data.data.info
        })
    },
    go_to_name_detail (e) {
      const nameStr = e.currentTarget.dataset.value
      router.push({ name: 'name_detail', query: { name: nameStr } })
    },
    want_alert (e) {
      Utils.alert({ content: e.currentTarget.dataset.value })
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close (e) {
      this.show = { detail: false, poe: false, ciyu: false, chengyu: false, people: false }
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    xml_go_to (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>
.mdd{
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.60rem 0rem;
  text-align:left;
  background-color: #fefefe;
  border-radius: 0.10rem;
}
.mdd li{
  display: inline-block;
  margin: 0.4rem 0.4rem
}
.help{
  display: block;
  text-align: center;
  color: #2b81af;
}

.one_key input, .one_key span, .one_key .van-switch {
  vertical-align:middle;
}
.van-switch{
  margin: 0rem 0.2rem 0rem 0.4rem;
}
.one_key span{
  margin-left: 0.2rem;
}
a.one_ming{
  font-size: 0.45rem;
  display: inline-block;
  width: 1.9rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 0.8rem;
  color: #fefefe;
  margin: 0.2rem 0rem 0.3rem 0.4rem;
  padding-top: 0.06rem;
  box-shadow:0.02rem 0.02rem 0.1rem #888;
}
.mings_div{
  text-align: left;
}

#jingxuan .names{
  margin: 0.3rem 0rem 0.3rem 0.6rem;
  display: block;
}
#jingxuan .names span{
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.5rem;
}
#jingxuan .names a{
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.5rem;
  color: #fefefe;
  margin-right: 0.30rem;
  line-height: 0.9rem;
  width: 1.8rem;
}
/* .name span{
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.5rem;
} */

.mdd .names .more{
  color: #81D3F8;
  background-color:rgba(129, 211, 248, 0.1);
  width: 1.8rem;
  height: 0.9rem;
  line-height: 0.9rem;
  font-size: 0.36rem;
}
.mdd .more{
  display: inline-block;
  color: #4fc08d;
  width: 1.8rem;
  height: 0.9rem;
  line-height: 0.9rem;
  font-size: 0.4rem;
}
.line_more{
  color: #4fc08d;
  height: 0.9rem;
  line-height: 0.9rem;
  font-size: 0.4rem;
  text-align: center;
}
.mdd ul{
  margin: 0rem 0.1rem;
}
.shiju{
  margin:0.3rem 0rem;
  padding: 0.3rem 0.5rem;
  text-align: left;
  background-color: #fefefe;
}
.shiju div{
  display: inline-block;
}
.shiju .left{
  width: 8rem;
  vertical-align: middle;
}

.shiju .right{
  width: 0.45rem;
  height: 0.4rem;
  line-height: 0.4rem;
  vertical-align: middle;
  color: lightgray;
  margin-left: 0.4rem;
}
.xing{
  display: inline-block;
  background-color: rgba(37, 130, 173, 0.3);
  height: 0.5rem;
  line-height: 0.5rem;
  margin: 0 0.08rem;
  border-radius: 0.05rem;
  font-size: 0.32rem;
  padding: 0.1rem 0.16rem;
  color: #fefefe;
}
.shiju_info{
  text-align: center;
  margin: 0.5rem 0 0.3rem 0;
}
.shiju_info .key_list{
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  max-width: 4rem;
  vertical-align: middle;
}
.xing_zi{
  display: inline-block;
  background-color: rgba(37, 130, 173, 0.3);
  height: 0.5rem;
  width: 0.5rem;
  line-height: 0.5rem;
  margin: 0.2rem 0.2rem;
  border-radius: 0.1rem;
  font-size: 0.32rem;
  padding: 0.1rem 0.1rem;
  color: #fefefe;
  text-align: center;
}
.zis{
  padding:  0rem 0.2rem;
}
button.to_be_vip{
  margin-top: 0.1rem;
}
</style>
